/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: ryanbair (https://sketchfab.com/ryanbair)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/nike-dunk-hawaii-6k-triangles-5a631f5439764fa18cf465f4cf19338f
title: Nike Dunk Hawaii - 6k triangles
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model(props) {
  const { nodes, materials } = useGLTF('/raw-models/first/scene.gltf')
  return (
    <group {...props} dispose={null}>
      <group group rotation={[-Math.PI / 2, 0, 1.8]} scale={0.28} position={[16, -17.8, -3.5]}>
        <mesh geometry={nodes.Object_2.geometry} material={materials.tex_u1_v1}/>
      </group>
    </group>
  )
}

useGLTF.preload('/raw-models/first/scene.gltf')
