/* Товары, отображаемые в разделе промо-товаров */
export const promoObjects = [
  {
    title: 'AIR MAX',
    price: '800$',
    image: require('../images/shoes/nike11.png'),
  },
  {
    title: 'AIR ZOOM',
    price: '750$',
    image: require('../images/shoes/nike12.png'),
  },
  {
    title: 'AIR FORCE',
    price: '820$',
    image: require('../images/shoes/nike13.png'),
  },
  {
    title: 'AIR FLARE',
    price: '500$',
    image: require('../images/shoes/nike14.png'),
  },
]
